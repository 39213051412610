/* Nav */
.main-nav {position: fixed;top: 0;left: 0;right: 0;z-index: 9; transform: translateY(-100%); transition: transform .8s cubic-bezier(0.35, 1, 0.45, 1),background 0.2s linear;background-color: #fff}
.main-nav-transparent {background-color: transparent;}
.main-nav .inner{display: flex; align-items: center; justify-content: center;padding: 30px 0;transition: padding .8s ease}
.main-nav .logo{flex:1; text-align: center;}

.main-nav .logo svg {width: 230px;height: auto;transition: width .4s ease}
.main-nav.nav-moved .logo svg {width: 140px;height: auto;}


.main-nav ul {list-style: none;padding: 0;margin: 0;width: 40%;}
.main-nav ul.nav-left {text-align: right;}

.main-nav ul li {display: inline-block;}
.main-nav ul li a{text-transform: uppercase; letter-spacing: 1px; padding: 2px 0; font-size: 15px; text-decoration: none; color: #171717;overflow: hidden; position: relative; display: flex; align-items:center;}
.main-nav ul li a span { position: relative; display: inline-block;}
.main-nav ul li a span:before {content: ''; position: absolute; bottom: 0;left: 0;right: 0;margin: 0 auto; height: 1px;background-color: #212121; display: block;width: 0;transition: width 0.3s;}
.main-nav ul li a:hover span:before { width: 100%;}
.main-nav ul li.current-nav-item a span:before, .main-nav ul li.current-menu-item a span:before {width: 100%;}

.main-nav ul.nav-left  {margin-right: 20px;}
.main-nav ul.nav-right  {margin-left: 20px;}

.main-nav ul.nav-left li {margin-right: 50px;}
.main-nav ul.nav-right li {margin-left: 50px;}

.nav-show {transform: translateY(0);}
.nav-moved {}
.nav-moved .inner{padding: 15px 0;}



.nav-scroll-down {transform: translateY(-100%);background-color: #fff}
.nav-scroll-up {transform: translateY(0);}
.nav-scroll-up.nav-moved {background-color: #fff}




/* Mobile nav */
.menu-opener, .nav-left-mobile, .navigation { display: none;}

.nav-spacer {height: 133px}


@media only screen and (max-width:1200px) {
	.main-nav .inner {padding: 25px 0;}
	.nav-moved .inner {padding: 15px 0;}

	.main-nav .logo svg {width: 170px;}
	.main-nav.nav-moved .logo svg {width: 120px;}

	.nav-spacer {height: 122px;}

	.main-nav ul li a{ font-size: 13px; }
	.main-nav ul.nav-left li {margin-right: 40px;}
	.main-nav ul.nav-right li {margin-left: 40px;}
}





@include media-breakpoint-down(md) {

	.logo svg {width: 130px;height: 57px;}

	.nav-spacer {height: 104px;}

	.main-nav .inner {padding: 30px 0;}
	.nav-moved .inner {padding: 15px 0;}

	.main-nav .logo svg {width: 150px;}
	.main-nav.nav-moved .logo svg {width: 100px;}


	.main-nav ul {display: none;}
	.nav-container {padding: 0 40px;}
	.body-menu-opened { overflow: hidden!important }
	.menu-opener { display: block; width: 44px; margin-left: 0; height: 44px;position: relative; cursor: pointer; overflow: hidden;right: -4px; }
	.nav-left-mobile {display: block; width: 44px; }

	.menu-opener span { position: absolute; left: 10px; display: block; width: 30px; height: 2px; background-color: #050505; transition: top .3s 0.3s, transform .3s; transform-origin: center center }
	.menu-opened .menu-opener span { transition: top .3s, transform .3s 0.3s; transform-origin: center center; background-color: #fff;  }
	.menu-opener span:first-child { top: 13px; transform: rotate(0deg) }
	.menu-opener span:nth-child(2) { top: 21px; transition: width 0.3s, opacity 0.4s }
	.menu-opener span:last-child { top: 29px }
	.menu-opened .menu-opener span:first-child { top: 21px; transform: rotate(45deg) }
	.menu-opened .menu-opener span:nth-child(2) { opacity: 0; width: 0 }
	.menu-opened .menu-opener span:last-child { top: 21px; transform: rotate(-45deg) }

	#Path_72 {transition: all 0.3s}
	.menu-opened #Path_72,
	.menu-opened #logo path	 {fill: #fff}
	.navigation { visibility: hidden; opacity: 0; position: fixed; z-index: 8; top: 0; bottom: 0; left: 0; right: 0; height: 100vh; transition: opacity 0.2s linear, visibility 0.4s 0s; background-color: #050505; overflow-y: auto; display: flex; text-align: center; color: #fff; flex-direction: column; justify-content: center; }
	.navigation.menu-opened { opacity: 1; visibility: visible; transition: opacity 0.2s linear, visibility 0s 0s, height 0.5s !important }
	.body-menu-opened .main-nav { background-color: #050505;transform: translateY(0); }

	.navigation > div.nav-mobile-top {height: 104px;}
	.navigation > div.nav-mobile-center {flex: 1; }
	.navigation > div.nav-mobile-bottom {height: 94px;padding: 30px 0 26px 0;}
	.navigation > div { padding: 30px 0;}
	.nav-mobile-top {}

	.nav-mobile-center {display: flex;flex-direction: column; justify-content: center;}
	.nav-mobile-center ul {list-style: none;padding: 0;margin: 0;}
	.nav-mobile-center  ul li a{text-transform: uppercase; letter-spacing: 1px; padding: 15px 0; font-size: 22px; text-decoration: none; color: #fff;position: relative; display: block;line-height: 1.8;}
	.nav-mobile-center  ul li a svg {margin-top: 40px;}
	.nav-mobile-center  ul li a path {}


	.nav-mobile-bottom {display: flex;flex-direction: column; justify-content: center; align-items: center; font-size: 14px; opacity: 0.7}



}

@media only screen and (max-height:560px) {
	.nav-mobile-center ul{padding-top: 30px;}
	.nav-mobile-center  ul li a{padding: 15px 0; }
	.nav-mobile-bottom {display: none;}
}