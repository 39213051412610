::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

input,
textarea,
select {
    @include transition(all .4s ease-in-out)
}

input[type="text"],
input[type="tel"],
textarea,
input[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}

input[type="submit"] {
    border-radius: 0px;
    border: none;
    padding: 15px 60px 15px 60px;
    font-size: 24px;
    color:#000;
    background-color: $color-yellow;
    transition:background-color 0.4s, color 0.4s;
}

input[type="submit"]:hover {background-color: #000; color: #fff}

input[type="submit"]:focus {
    background-color: #000; color: #fff;
    border: none;
    box-shadow: none;
}


.float-label {
    position: relative;
    /* margin-bottom: 20px; */
}

.float-label label {
    cursor: auto;
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 18px;
    margin-bottom: 0;
    font-size: 20px;
    display: inline-block;
    padding: 0;
    background-color: rgba(255, 255, 255, 0);
    -moz-transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    -o-transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    -webkit-transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    color: #121212;
    pointer-events: none;
    line-height: 1.5;
}

.float-label label.active {
    top: 10px;
    font-size: 17px;
    width: auto;
    opacity: 0.5
}




.float-label input[type="text"],
.float-label input[type="email"],
.float-label input[type="date"],
.float-label input[type="password"],
.float-label input[type="tel"],
.float-label input[type="search"],
.float-label select {
    font-family: 'Poppins', sans-serif;
    height: 66px !important;
    line-height: 26px !important;
    padding: 16px 20px 0 20px !important;
    font-size: 18px !important;
    background-color: transparent;
    color: #000 !important;
    min-width: 140px;
    transition: border 0.4s, background-color 0.4s;
    width: 100%;
}

.float-label input[type="text"]:hover,
.float-label input[type="email"]:hover,
.float-label input[type="number"]:hover,
.float-label input[type="date"]:hover,
.float-label input[type="password"]:hover,
.float-label input[type="tel"]:hover,
.float-label textarea:hover,
.float-label button:hover,
.float-label select:hover {}

.float-label input[type="text"]:focus,
.float-label input[type="email"]:focus,
.float-label input[type="number"]:focus,
.float-label input[type="date"]:focus,
.float-label input[type="password"]:focus,
.float-label input[type="tel"]:focus,
.float-label textarea:focus,
.float-label button:focus,
.float-label select:focus {
    outline: none;
    border-color: $color-yellow !important;
    box-shadow: none;
    background-color: #F5F5F5
}

textarea.form-control {
    height: 110px;
    font-family: 'Poppins', sans-serif;
    padding: 40px 20px 0 20px !important;
    color: #000 !important;
    font-size: 18px !important;
}




.float-label select {
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    transition: all 0.3s;
    z-index: auto !important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.832' height='4.123' viewBox='0 0 6.832 4.123'%3E %3Cg id='icon-down' transform='translate(-944.092 -348.606)'%3E %3Cpath id='Path_78' data-name='Path 78' d='M11122.549%2C2069.012l3.063%2C3.063%2C3.063-3.062' transform='translate(-10178.104 -1720.052)' fill='%23fff' stroke='%23121212' stroke-width='1'/%3E %3C/g%3E %3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 12px;
    margin-bottom: 0;
}



label {
    margin-bottom: 0;
}

label.checkbox {
    margin-top: 3px;
    margin-right: 5px;

}

label.checkbox input {
    margin-top: 3px;
    margin-right: 5px;
}

label.checkbox a {
    color: inherit;
}

label.checkbox a:hover {
    text-decoration: none;
}




/** CF7 overwrites **/

span.wpcf7-list-item {
    margin: 2px 10px 0 0;
}


.required-fields {
    float: right;
    color: #313843;
    font-size: 16px;
    padding-top: 26px;
    padding-right: 30px;
}

.wpcf7-form {
    margin-bottom: 0;
}

.required {
    display: inline !important;
}

.wpcf7-form-control-wrap {
    position: relative;
    display: block;
}

span.wpcf7-not-valid-tip {
    color: #d25b5b !important;
    font-size: 12px !important;
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    width: 100%;
    height: 1px;
    bottom: 0px !important;
    background: #d25b5b !important;
    text-indent: -9999px !important;
}

.form-group-select span.wpcf7-not-valid-tip {
    bottom: 11px !important;
}

div.wpcf7-response-output {
    height: 0;
    text-align: left;
    margin: 0 0 0 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-size: 14px !important;
    clear: both;
    border-radius: 0;
    position: relative;
    bottom: -10px;
}

.wpcf7 form.invalid .wpcf7-response-output  {color: #d25b5b;}


div.wpcf7-validation-errors {
    color: #d25b5b !important;
}

div.wpcf7-mail-sent-ok {
    color: #398f14 !important;
}


/* Loader */
.ajax-loader,
.ajax-loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.ajax-loader {
    display: inline;
    position: absolute;
    z-index: 4;
    top: 50%;
    margin-top: -11px !important;
    right: 20px;
    background-image: none !important;
    font-size: 10px;
    /* position: relative!important; */
    text-indent: -9999em !important;
    border-top: 2px solid rgba(255, 255, 255, 0.2) !important;
    border-right: 2px solid rgba(255, 255, 255, 0.2) !important;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2) !important;
    border-left: 2px solid #ffffff !important;
    transform: translateZ(0) !important;
    animation: loading 1.1s infinite linear !important;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-bottom: 1px solid $color-yellow;
    background-color: transparent;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 80px #F5F5F5 inset !important;
}



@media (max-width: 960px) {}