body {transition:background-color 0.6s ease; -webkit-font-smoothing: antialiased;text-shadow: 1px 1px 1px rgba(0,0,0,0);}

a {text-underline-position: under;}
a:hover {text-decoration: none;}

/* ::-moz-selection { background: $color-yellow; }
::selection { background: $color-yellow; } */


.t-6rem {top:6rem}
.t-8rem {top:8rem}
.t-0 {top:0}
.t-min {top:-3rem}
.r-0 {right:0}

.image-50 {padding-bottom: 50%;}
.image-42 {padding-bottom: 42%;}
.image-63 {padding-bottom: 63%;}
.image-75 {padding-bottom: 75%;}
.image-100 {padding-bottom: 100%;}
.image-105 {padding-bottom: 105%;}
.image-128 {padding-bottom: 128%;}
.image-132 {padding-bottom: 132%;}

.flex-80p {flex: 0 0 80px;}
.flex-180p {flex: 0 0 180px;}

.image-full {height: 100vh;width: 100%; position: absolute;top: 0;left: 0;right: 0; opacity: 0.2;}
.image-full img, .image-full video {position: absolute;top: 0;left: 0;width: 100%;object-fit: cover;}

.height-2 {height: 2px!important;}
.w-90 {width: 90%}

.mw-730 {max-width: 730px;}
.mw-550 {max-width: 550px;}

.image-sticky {max-height:750px}

.image-team {padding-bottom: 130%; max-height: 100vh}

@include media-breakpoint-up(md) { 
	.image-sticky {height: calc(100vh - 12rem)}
}


@include media-breakpoint-down(xxl) {
	.image-sticky {height: calc(100vh - 12rem)}
}
@include media-breakpoint-down(xl) {
	.image-sticky {height: calc(100vh - 12rem)}
}
@include media-breakpoint-down(lg) {
	.image-sticky {height: calc(100vh - 10rem)}
	.image-lg-132 {padding-bottom: 132%!important;}
}

@include media-breakpoint-up(sm) {
	.position-sm-absolute {position: absolute;}
}


@include media-breakpoint-down(sm) {
}


@include media-breakpoint-down(md) { 
	.image-sticky {height: auto}
	.image-md-vw {padding-bottom: 114vw!important;}
	.image-md-132 {padding-bottom: 132%!important;}
	.image-md-75 {padding-bottom: 75%!important;}
	.image-md-200 {padding-bottom: 200%!important;}
	.pb-md-0-down {padding-bottom: 0;}
}


.image-component-mask-left, .image-component-mask-right {width: 3rem; height: 100%; background: white; position: absolute; z-index: 1; }
.image-component-mask-left {left: 0; }
.image-component-mask-right {right: 0; }


@include media-breakpoint-down(xxl) {
	.image-component-mask-left, .image-component-mask-right {width: 2.5rem;}
}
@include media-breakpoint-down(xl) {
	.image-component-mask-left, .image-component-mask-right {width: 2rem;}
}
@include media-breakpoint-down(lg) {
	.image-component-mask-left, .image-component-mask-right {width: 1.5rem;}
}


.row-cta {position: relative; z-index: 2}
.row-cta a {text-decoration: none; color: #212121; display: flex; flex-direction:column;justify-content: space-between; position: relative;}
.row-cta a > div {display: flex; flex-direction:column;justify-content: center;height: 100%; }



@include media-breakpoint-up(md) {
	.row-cta:hover a {opacity: 0.4}
	.row-cta:hover a:hover {opacity: 1}
	.row-cta a {padding:100px 0 100px 0;  min-height: 440px; transition:opacity 0.4s}
	.row-cta a:before {border: 1px solid #BFBFBF; content: ''; background-color: #fff; display: block;position: absolute;top: 0;bottom: 0;left: 0;right: 0; z-index: -1; transform-origin:center center; transition:top 0.4s 0.1s,bottom 0.4s 0.1s, background-color 0.6s ease, border-color 0.4s}
	.row-cta a:hover:before {top:-15px;bottom: -15px;background-color: #000;border-color:#000; }

	.row-cta a:first-child:before {right: -1px;}
	.row-cta a:last-child:before  {left: -1px;}


	.row-cta a:after {content: ''; background-size: cover;background-position: center; filter: sepia(1); opacity: 0; display: block;position: absolute;top: 0;bottom: 0;left: 0;right: 0;width: 100%; z-index: 0; transform-origin:center center; transition: top 0.4s 0.1s,bottom 0.4s 0.1s, opacity 0.6s 0.1s}
	.row-cta a:hover:after {top:-15px;bottom: -15px; opacity: 0.3;  }

	.row-cta a > div {}


	.row-cta a em {display: block;text-align: right;}
	.row-cta a svg path {transition:stroke 0.3s}
	.row-cta a:hover svg path {stroke:#fff}
	.row-cta a:hover svg path {stroke:#fff}

	.row-cta a h2 {margin-bottom: 0;position: relative;z-index: 1; transition: color 0.4s}
	.row-cta a:hover h2 {color: #fff;}
	.read-more-holder { opacity: 0; position: absolute;right: 50px;bottom: 20px;z-index: 1; transition: opacity 0.4s}
	.row-cta a:hover .read-more-holder { opacity: 1;}
	.read-more-holder .btn-underline {color: #fff;}
	.read-more-holder .btn-underline:after {}
}


@media (max-width: 2000px) {
	.row-cta a:first-child:before {border-left: none;}
	.row-cta a:last-child:before  {border-right: none;}
}

@include media-breakpoint-down(md) {
	.row-cta {margin: 0 15px 30px 15px;}
	.row-cta a {border: 1px solid #C6C6C6; margin-bottom: 10px;padding: 30px 0;}
	.row-cta a h2 {color: #5C6262;margin-bottom: 20px;}
	.row-cta a svg {width: 40px;height: auto;}
	.row-cta a svg path {stroke:#989C9C}


	.row-cta a:before {content: ''; background-color: #000; display: block;position: absolute;top: 0;bottom: 0;left: 0;right: 0;width: 100%; z-index: 0; transform-origin:center center;}
	.row-cta a:after {content: ''; background-size: cover;filter: sepia(1); opacity: 0.3; display: block;position: absolute;top: 0;bottom: 0;left: 0;right: 0;width: 100%; z-index: 0; transform-origin:center center;}
	.row-cta a h2 {color: #fff;position: relative;z-index: 1; }
	.read-more-holder .btn-underline {color: #fff;}

}

@include media-breakpoint-only(md) {
	.row-cta a h2 {font-size: 22px}
}


.footer-menu {display: flex; flex-wrap:wrap; list-style: none;padding: 0;margin: 0;}
.footer-menu li{width: 50%;}
.footer-menu li a{text-decoration: none; color:#fff; font-size: 16px; position: relative;margin-bottom: 10px; padding: 5px 0; display: inline-block;}
.footer-menu li a:before {content: ''; position: absolute; bottom: 5px;left: 0;right: 0;height: 1px;background-color: $color-yellow; display: block;width: 0;transition: width 0.3s;}
.footer-menu li a:hover:before { width: 100%;}

footer address {font-size: 16px; line-height: 1.4; color: #AAA9A9;padding-top: 15px;}

.social-menu {padding: 0;margin: 0; list-style: none;display: flex}
.social-menu li a{ display: block; padding: 10px 30px 10px 0;}
.social-menu li a svg path{transition:fill 0.4s}
.social-menu li a:hover svg path{fill:#fff}

.copy {font-size: 14px; color:#B4B4B4;margin-top: 40px;}

@include media-breakpoint-down(lg) {

}
@include media-breakpoint-down(md) {
	.copy {text-align: center;}
}




.number {@include font-size(7rem);line-height: 0.82; font-family: $font-secondary; color:$color-yellow;margin-bottom: 10px;}


.cta-bottom {background-color: #fff; display: block; text-decoration: none; transition: background-color 0.6s}
.cta-bottom .h1 { color: #333B3B!important; line-height: 1.2}
.cta-bottom:hover {background-color: #000}

.cta-bottom .cta-text {color: #272929; text-decoration: none;@include font-size(1.3rem); position: relative;}
.cta-bottom .cta-text:hover {color: #272929;}
.cta-bottom .cta-text span { position: relative; display: inline-block; transition:transform 0.3s; z-index: 1 }
.cta-bottom .cta-text span em { position: relative;z-index: 1; font-style: normal;}

.cta-bottom .cta-text.big {@include font-size(1.5rem); }
.cta-bottom .cta-text span:before {content: ''; position: absolute;z-index: 0;width: 100%; bottom: 0px;right: 0;height: 1px; background-color: #D6D7D7; transition:width 0.3s}
.cta-bottom .cta-text:hover span:before { width: 0;}

.cta-bottom .cta-text svg {transition:transform 0.3s;margin-left: 10px;}
.cta-bottom .cta-text:hover svg {transform:translateX(10px)}

.cta-bottom:hover .h1 {color: $color-yellow!important}
.cta-bottom:hover .cta-text {color: #fff!important}
.cta-bottom:hover .cta-text svg path{stroke: #fff!important}
.cta-bottom:hover .cta-text span:before {}


.logo-off {width: calc(100% + 10vw);margin-bottom: 40px;}
.flex-logo {flex: 0 0 calc(60px + 10vw);}

@include media-breakpoint-down(sm) {
	.logo-off {width:190px;margin-left: -3rem;}
}


.logo-backdrop {position: absolute;top: -1.5rem;left: 0;z-index: 5;right: 0;}
.logo-backdrop img{max-width: 100%;}


.blockquote-bordered {max-width: 390px;border-top: 1px solid #4C4B4B;padding-top: 30px;margin-top: 60px;}


.row-team a{text-decoration: none;}
.row-team a .cta-text em {font-style: normal;}
.row-team a .cta-text span {position: relative;margin-right: 5px;}
.row-team a .cta-text span:before { content: ''; position: absolute; z-index: 0; width: 0; bottom: 0px; left: 0; height: 1px; background-color: #999; transition: width 0.3s; }
.row-team a:hover .cta-text span:before {width: 100%;}
.row-team a .cta-text svg{transition: transform 0.3s;}
.row-team a .cta-text:hover svg{transform: translateX(5px);}