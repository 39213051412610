.barba-container {max-width: 2000px;margin: 0 auto;}
section, .section {padding:6rem 0;}
.container {padding:0 6rem;}

.inner {max-width: 1560px;margin: 0 auto; width: 100%;}
.inner-lg {max-width: 1220px;margin: 0 auto; width: 100%;}
.inner-md {max-width: 1020px;margin: 0 auto; width: 100%;}
.inner-sm {max-width: 740px;margin: 0 auto; width: 100%;}

.outdent-left {margin-left:-6rem;width: calc(100% + 6rem)!important; }
.outdent-right {margin-right:-6rem;width: calc(100% + 6rem)!important; }



/* Responsive down*/
@include media-breakpoint-down(xxl) {
	section, .section {padding:5rem 0;}
	.container {padding:0 5rem;}
}

@include media-breakpoint-down(xl) {
	section, .section {padding:4rem 0;}
	.container {padding:0 4rem;}

	.outdent-left {margin-left:-4rem;width: calc(100% + 4rem)!important; }
	.outdent-right {margin-right:-4rem;width: calc(100% + 4rem)!important; }
	
}

@include media-breakpoint-down(lg) {
	section, .section {padding:3rem 0;}
	.container {padding:0 3rem;}

	.indent-left-lg {margin-left: -3rem;width: calc(100% + 3rem)!important;}
	.indent-right-lg {margin-right: -3rem;width: calc(100% + 3rem)!important;}

	.outdent-left {margin-left:-3rem;width: calc(100% + 3rem)!important; }
	.outdent-right {margin-right:-3rem;width: calc(100% + 3rem)!important; }
}
@include media-breakpoint-down(md) {
	.outdent-md-half {margin:0 -1.5rem;width: calc(100% + 3rem)!important; }

}

@include media-breakpoint-down(sm) {
	.container {padding:0 1.6rem;}
	.outdent-md-half {margin:0 -0.8rem;width: calc(100% + 1.6rem)!important; }

	.indent-left-lg {margin-left: -1.6rem;width: calc(100% + 1.6rem)!important;}
	.indent-right-lg {margin-right: -1.6rem;width: calc(100% + 1.6rem)!important;}

	.outdent-left {margin-left:-1.6rem;width: calc(100% + 1.6rem)!important; }
	.outdent-right {margin-right:-1.6rem;width: calc(100% + 1.6rem)!important; }

}