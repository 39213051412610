.font-primary {font-family: $font-primary}
.font-secondary {font-family: $font-secondary}

p.small {font-size: 16px;margin-bottom: 10px;}

.ls-0 {letter-spacing: 0!important}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
blockquote {
     font-family: $font-secondary;
         line-height: 1.2;
}


h1, .h1, blockquote  {
  @include font-size(3.4rem);
  color: #0A0A0A;
  margin-bottom: 2.2rem;
}
h1.big, .h1.big{
  @include font-size(4.1rem);
  color: #0A0A0A;
}

h2, .h2 {
  @include font-size(3rem);
  margin-bottom: 2rem;
}
h3, .h3 {
  @include font-size(2.6rem);
  color: #191818
}
h4, .h4 {
  @include font-size(2.2rem);
}
h4.small, .h4.small {
  @include font-size(1.4rem);
}
h5, .h5 {
  @include font-size(1.9rem);
  margin-bottom: 2rem;
}
h6, .h6 {
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: $font-primary;
  color: #191818;
  margin-bottom: 2.4rem;
}

blockquote {display: flex;}
blockquote:before, blockquote:after {content:""; display: block;width: 58px;height: 44px;flex: 0 0 58px; background-size: contain; background-repeat: no-repeat;}
blockquote:before {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='58.4' height='43.2' viewBox='0 0 58.4 43.2'%3E %3Cpath id='Path_2715' data-name='Path 2715' d='M33-143.2%2C31.8-146c-15.6%2C7.6-25%2C17.6-25%2C27.6A17.918%2C17.918%2C0%2C0%2C0%2C16-102.8c5-2.2%2C9.8-4.6%2C14.8-7.4l-.6-2.2c-6-.6-10.6-5-10.6-13C19.6-131%2C21.6-136.8%2C33-143.2Zm32.2%2C0L64-146c-15.6%2C7.6-24.8%2C17.6-24.8%2C27.6a17.735%2C17.735%2C0%2C0%2C0%2C9%2C15.6c5-2.2%2C10-4.6%2C15-7.4l-.6-2.2c-6-.6-10.8-5-10.8-13C51.8-131%2C53.8-136.8%2C65.2-143.2Z' transform='translate(-6.8 146)' fill='%23f5b841'/%3E %3C/svg%3E ");margin:12px 15px 0 0}
blockquote:after {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='58.4' height='43.2' viewBox='0 0 58.4 43.2'%3E %3Cpath id='Path_2716' data-name='Path 2716' d='M33%2C9.2%2C31.8%2C12C16.2%2C4.4%2C6.8-5.6%2C6.8-15.6A17.918%2C17.918%2C0%2C0%2C1%2C16-31.2c5%2C2.2%2C9.8%2C4.6%2C14.8%2C7.4l-.6%2C2.2c-6%2C.6-10.6%2C5-10.6%2C13C19.6-3%2C21.6%2C2.8%2C33%2C9.2Zm32.2%2C0L64%2C12C48.4%2C4.4%2C39.2-5.6%2C39.2-15.6a17.735%2C17.735%2C0%2C0%2C1%2C9-15.6c5%2C2.2%2C10%2C4.6%2C15%2C7.4l-.6%2C2.2c-6%2C.6-10.8%2C5-10.8%2C13C51.8-3%2C53.8%2C2.8%2C65.2%2C9.2Z' transform='translate(65.2 12) rotate(180)' fill='%23f5b841'/%3E %3C/svg%3E "); background-repeat: no-repeat;  align-self:flex-end; margin:0 0 12px 15px; background-position: bottom}


.lead {@include font-size(1.4rem); color:#4C4B4B;}
.lead.big {@include font-size(1.6rem);margin-bottom: 3rem;line-height: 1.5;}

@include media-breakpoint-down(xxl) {}
@include media-breakpoint-down(xl) {
  .lead.big {@include font-size(1.5rem);margin-bottom: 3rem;}

}
@include media-breakpoint-down(lg) {
  .lead.big {@include font-size(1.4rem);margin-bottom: 2rem;}
  blockquote:before, blockquote:after {width: 38px;flex: 0 0 38px;}
}

@include media-breakpoint-down(md) {
  blockquote:before, blockquote:after {width: 25px;flex: 0 0 25px;}
}
@include media-breakpoint-down(sm) {
  blockquote {display: block;}
  blockquote:before {height: 35px }
  blockquote:after {display: none; }
}


