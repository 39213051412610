.slider-overflow .flickity-viewport {overflow: visible;}
.slider-overflow .carousel-cell {width: 28%;margin-right: 30px; text-decoration: none; color: inherit;}
.slider-overflow .carousel-cell p {max-width: 80%;margin-bottom:15px; @include font-size(1.3rem); color: #1E1E1F; line-height: 1.5;}
.slider-overflow .carousel-cell .btn-underline { opacity: 0; transition:opacity 0.6s; font-size: 20px}
.slider-overflow .carousel-cell:hover .btn-underline { opacity:1}
.slider-overflow a.carousel-cell div img {transition:transform 0.6s}
.slider-overflow a.carousel-cell:hover div img {transform:scale(1.05)}


.slider-overflow .carousel-cell:first-child {width: 40%;}
.slider-overflow .carousel-cell:first-child p {font-family: $font-secondary;@include font-size(1.9rem);line-height: 1.4 }
.slider-overflow .carousel-cell:first-child .btn-underline { opacity: 1; font-size: 22px}


@include media-breakpoint-down(xxl) {}
@include media-breakpoint-down(xl) {
	.slider-overflow .carousel-cell {width:46%;margin-right: 15px;}
	.slider-overflow .carousel-cell:first-child {width: 46%;}
}
@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {
	.slider-overflow .carousel-cell {width:60%;}
	.slider-overflow .carousel-cell:first-child {width: 60%;}

	.slider-overflow .carousel-cell .btn-underline { opacity: 1; font-size: 18px}
	.slider-overflow .carousel-cell p {@include font-size(1.2rem);line-height: 1.3;}
	.slider-overflow .carousel-cell:first-child p {@include font-size(1.7rem);line-height: 1.2}
	.slider-overflow .carousel-cell:first-child .btn-underline { opacity: 1; font-size: 18px}

}
@include media-breakpoint-down(sm) {
	.slider-overflow .carousel-cell {width:95%;}
	.slider-overflow .carousel-cell:first-child {width: 95%;}
}
