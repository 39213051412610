
.split-holder {width: 100%;   -webkit-text-rendering: optimizeSpeed;text-rendering: optimizeSpeed;-webkit-transform: translateZ(0);}


.split-holder-slide {width: 100%;   -webkit-text-rendering: optimizeSpeed;text-rendering: optimizeSpeed;-webkit-transform: translateZ(0);}


.split-parent{ overflow: hidden;}
.split-text {}

