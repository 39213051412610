
.carousel-cut img {max-width: auto;width: auto;  height: 620px;margin: 0 7px;}


@media only screen and (max-width:1400px) {
  .carousel-cut img { height: 520px;}
}


@media only screen and (max-width:1100px) {
  .carousel-cut img { height: 420px;}
}

@media only screen and (max-width:960px) {
  .carousel-cut img { height: 360px;margin: 0 5px;}
}

@media only screen and (max-width:768px) {
  .carousel-cut .carousel-cell {width: 80%;margin: 0 3px;}
  .carousel-cut img { height: 300px;margin: 0 3px;}
}


@media only screen and (max-width:660px) {
  .carousel-cut img { height: 260px;}
}

@media only screen and (max-width:580px) {
  .carousel-cut img { height: 200px}
}

@media only screen and (max-width:450px) {
  .carousel-cut img { height: 180px}
}

@media only screen and (max-width:370px) {
  .carousel-cut img { height: 170px}
}

@media only screen and (max-width:350px) {
  .carousel-cut img { height: 160px}
}

