.bg-yellow {background-color: $color-yellow!important}
.bg-black {background-color: #000!important}
.bg-light {background-color: #F5F5F5!important; transition: background-color 0.6s ease}


.color-white {color:  #fff!important}
.color-yellow {color: $color-yellow!important}
.color-black {color: #000!important}

.border-yellow {border-color:$color-yellow!important}


/* Text with backgrounds */
.bg-black h2, .bg-black h1, .bg-black .h1, .bg-black .color-black {color: $color-yellow!important}
.bg-black .h5, .bg-black .h5 { color: #fff}
.bg-black p {color: #ccc!important}
.bg-black .lead.big {color: #CCCCCC}
.bg-black .lead {color: #CCCCCC}
.bg-black .bg-light {background-color: #000!important}

.bg-black .row-cta a:before { background-color: #000;}
.bg-black .row-cta a:before {border-color: #333; }

