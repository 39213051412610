
.image-absolute {width: 100%;position: relative; overflow: hidden;z-index: 2}
.image-absolute img, .image-absolute video {position: absolute;top: 0;left: 0;width: 100%;object-fit: cover;}

.scrub-parallax {}
.scrub-parallax img, .scrub-parallax video {object-fit: cover;-o-object-fit: cover;object-position: 50% 50%;height: 110%!important;top: -15%!important;}

.zoom-in {}
.zoom-in img, .scrub-zoom video {transform:scale(1.1); transform-origin:center center; object-fit: cover;}



.reveal { visibility: hidden; position: relative; overflow: hidden; }
.reveal img { height: 100%; width: 100%; object-fit: cover;}
