.history-nav {list-style: none;padding: 0; margin: 0;}
.history-nav li a {white-space: nowrap; color: #fff;@include font-size(3.4rem); text-decoration: none; font-family: $font-secondary; color: $color-yellow; transition:color 0.4s; }
.history-nav li a:hover, .history-nav li.active a { color: #fff}


.what-we-do-nav {list-style: none;padding: 0; margin:-8px 0 0 0; position: relative;z-index: 3}
.what-we-do-nav li {display: flex;justify-content:flex-end}
.what-we-do-nav li a {letter-spacing: 0.5px; white-space: nowrap;padding: 8px 0; position: relative; display: inline-block;width: 62px; color: #000;font-size: 17px; text-transform: uppercase; text-decoration: none; color: $color-yellow; transition:color 0.4s; }


.what-we-do-nav li a:hover, .what-we-do-nav li.active a { color: #000}
.what-we-do-nav li.active a {font-weight: 600}



.what-we-do-nav li a:before { content: ''; position: absolute; top: 20px; left: -35px; margin: 0 auto; height: 2px; background-color: #212121; display: block; width: 0; transition: width 0.3s; }
.what-we-do-nav li.active a:before, .what-we-do-nav li.active-2 a:before { width: 25px; }

.bg-black .what-we-do-nav li a:hover, .bg-black .what-we-do-nav li.active a, .bg-black .what-we-do-nav li.active-2 a {color: #fff}
.bg-black .what-we-do-nav li a:before {background-color: #fff}


@include media-breakpoint-down(xl) {
.history-nav li a {@include font-size(3rem); }
}


@include media-breakpoint-down(lg) {
.history-nav li a {@include font-size(2.5rem); }
}