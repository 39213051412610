.button { border: none; margin: 0; padding: 0; width: auto; overflow: visible; background: transparent;transition:transform 0.4s, opacity 0.4s; color: inherit; font: inherit;   line-height: normal; -webkit-font-smoothing: inherit; -moz-osx-font-smoothing: inherit;-webkit-appearance: none; text-align: inherit; outline: none; cursor: pointer; }
.button::-moz-focus-inner { border: 0; padding: 0; }
.button:focus {outline: none;}
.button:disabled {opacity: 0.3;transform:translateX(0px)!important; cursor: no-drop;}
.button.btn-left {margin-right: 4px; transitio:transform 0.4s}
.button.btn-right {margin-left: 4px;}

.button.btn-left:hover {transform:translateX(-3px)}
.button.btn-right:hover {transform:translateX(3px)}





.btn-underline {color: #272929; text-decoration: none;@include font-size(1.3rem); position: relative;}
.btn-underline:hover {color: #272929;}
.btn-underline span { position: relative;z-index: 1; display: inline-block; transition:transform 0.3s; }
.btn-underline:hover span {transform:translateY(-3px)}

.btn-underline.big {@include font-size(1.5rem); }

.btn-underline:after {content: ''; position: absolute;bottom: 2px;left: 0;right: 0;height: 2px; background-color: $color-yellow; transition:height 0.3s}
.btn-underline:hover:after {height: 12px;}


.btn-arrow {text-decoration: none;}
.btn-arrow span{ position: relative;}
.btn-arrow span:after {content: ''; position: absolute;bottom: 0px;left: 0;right: 0;width: 0; height: 1px; background-color: #000; transition:width 0.3s}
.btn-arrow span:hover:after {width: 100%;}

.btn-arrow svg {transition:transform 0.3s;margin-left: 5px;}
.btn-arrow:hover svg {transform:translateX(6px)}


.link-big {font-family: $font-secondary; color: #000000; text-decoration: none;@include font-size(1.9rem);}
.link-big.btn-underline.big {@include font-size(1.9rem);}



